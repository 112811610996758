import { SvgProps } from '..';

function BroadCloud(props: SvgProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      style={props.style}
      viewBox="0 0 720 210"
    >
      <path
        fill="#282F39"
        d="M498.3 138a23.2 23.2 0 0 1-17.2-7.5 25.2 25.2 0 0 1-6.9-17.4c0-4.6 1-8.7 3-12.4a23.1 23.1 0 0 1 21-12.2c4.5 0 8.5 1 11.9 3.1 3.4 2 6 4.8 7.6 8.3l-6 2A14.6 14.6 0 0 0 506 96a16 16 0 0 0-8-2c-3.2 0-6.2.8-8.8 2.4a18 18 0 0 0-6.3 6.8 20 20 0 0 0-2.3 9.8 20 20 0 0 0 8.7 16.7c2.6 1.8 5.6 2.6 8.7 2.6a16.8 16.8 0 0 0 11.1-4 9.5 9.5 0 0 0 3-4.2l6 1.9c-.8 2.2-2.2 4.3-4.2 6-2 1.9-4.3 3.3-7 4.3a24 24 0 0 1-8.6 1.5zM528.3 69.9h6.3v56.6c0 2.4.4 4 1.3 4.7.8.8 1.9 1.1 3 1.1 1.2 0 2.3 0 3.4-.3l2.8-.8 1.2 5a25 25 0 0 1-8.6 1.7c-3 0-5.2-.8-6.9-2.5a9.2 9.2 0 0 1-2.5-6.8V69.9zM573.5 138a23 23 0 0 1-17-7.4 25 25 0 0 1-6.8-17.3 25.9 25.9 0 0 1 6.8-17.4 23.5 23.5 0 0 1 26.6-5.4 23.7 23.7 0 0 1 12.5 13.3 25.9 25.9 0 0 1-5 26.8 23 23 0 0 1-17.1 7.3zM556 113.3c0 3.6.8 6.8 2.3 9.7 1.6 2.8 3.7 5.1 6.3 6.8a16 16 0 0 0 8.8 2.5c3.2 0 6-.8 8.7-2.5a20 20 0 0 0 8.7-16.6 19.9 19.9 0 0 0-8.7-16.7 15.8 15.8 0 0 0-17.4.1 19.9 19.9 0 0 0-8.7 16.7zM607.3 117.3v-28h6.3v26.9c0 5.4 1 9.5 2.8 12.2 1.9 2.7 4.6 4 8.3 4 2.5 0 4.9-.5 7.2-1.6a20.3 20.3 0 0 0 10.2-11.5v-30h6.2v40.2c0 .8.2 1.5.5 1.9.4.4 1 .6 1.7.7v5a212 212 0 0 1-3 .1 5.2 5.2 0 0 1-3.1-1.5 4.5 4.5 0 0 1-1.5-3l-.1-6.5a22.8 22.8 0 0 1-20.2 11.7c-5 0-8.8-1.7-11.4-5.2a25.7 25.7 0 0 1-3.9-15.4zM659.2 113.3c0-4.5 1-8.6 2.8-12.4 1.9-3.7 4.5-6.7 7.8-9 3.3-2.2 7-3.4 11.3-3.4 4 0 7.6 1 10.7 3.2 3.2 2.1 5.7 4.7 7.4 7.7V69.9h6.3v59.6c0 .8.2 1.5.6 1.9.3.4.9.6 1.6.7v5c-1.2.1-2.2.2-2.8.1a5 5 0 0 1-3.4-1.6c-1-1-1.5-2-1.5-3.2v-4.7a21 21 0 0 1-7.7 7.5 20.9 20.9 0 0 1-19.3.7 22.8 22.8 0 0 1-7.3-5.5 25.7 25.7 0 0 1-6.5-17zm40 7.3V106c-.8-2.2-2-4.2-3.8-6a21.4 21.4 0 0 0-6-4.2 15.4 15.4 0 0 0-19 4.3 21.7 21.7 0 0 0-3.3 20.6 19.1 19.1 0 0 0 9.4 10.4c2.2 1 4.5 1.4 7 1.4 1.6 0 3.3-.3 5-.9 1.7-.6 3.3-1.4 4.9-2.5 1.6-1 2.9-2.3 4-3.8 1-1.5 1.6-3 1.8-4.6z"
      />
      <defs>
        <path
          id="a"
          d="M132.3 40.9h-83a31.3 31.3 0 1 0 0 62.6h29.6l27.7-47.2A31.4 31.4 0 0 1 132.9 41h-.6z"
        />
      </defs>
      <clipPath id="b">
        <use xlinkHref="#a" overflow="visible" />
      </clipPath>
      <linearGradient
        id="c"
        x1="541.5"
        x2="556.1"
        y1="1238.8"
        y2="1238.8"
        gradientTransform="rotate(104.7 2846.4 1616.2) scale(3.7848)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f99b1c" />
        <stop offset=".4" stopColor="#f6811e" />
        <stop offset="1" stopColor="#f15d22" />
      </linearGradient>
      <path
        fill="url(#c)"
        d="M25.4 12.6 2.5 99.4l122.9 32.3 22.9-86.8z"
        clipPath="url(#b)"
      />
      <defs>
        <path
          id="d"
          d="M133.2 40.9a31.3 31.3 0 0 0-26.7 15.4L91.4 82.2l26.9 45.8a31.3 31.3 0 0 1-26 47.1 31.2 31.2 0 0 0 26.2-15.4l42-71.7A31.3 31.3 0 0 0 134 40.9h-.8z"
        />
      </defs>
      <clipPath id="e">
        <use xlinkHref="#d" overflow="visible" />
      </clipPath>
      <linearGradient
        id="f"
        x1="907"
        x2="921.6"
        y1="1049.5"
        y2="1049.5"
        gradientTransform="scale(-4.4768) rotate(40.2 1906.4 -768)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#37b555" />
        <stop offset=".4" stopColor="#2f9d4c" />
        <stop offset="1" stopColor="#21773d" />
      </linearGradient>
      <path
        fill="url(#f)"
        d="M235.5 96.8 123.9 2.5 25.2 119.2l111.6 94.3z"
        clipPath="url(#e)"
      />
      <defs>
        <path
          id="g"
          d="M64.3 159.7a31.3 31.3 0 0 0 26.9 15.5h.2a31.3 31.3 0 0 0 31.2-30.6v-1.5a31.1 31.1 0 0 0-4.3-15l-14.4-24.6H49.2c-11.3 0-21.2-6-26.7-15l41.8 71.2z"
        />
      </defs>
      <clipPath id="h">
        <use xlinkHref="#g" overflow="visible" />
      </clipPath>
      <linearGradient
        id="i"
        x1="527.7"
        x2="542.3"
        y1="853"
        y2="853"
        gradientTransform="scale(4.2425) rotate(2.6 18367.3 -10788.7)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ffe100" />
        <stop offset="1" stopColor="#dbbb00" />
      </linearGradient>
      <path
        fill="url(#i)"
        d="m18.6 175 103.8 4.7 4.1-91.1-103.8-4.7z"
        clipPath="url(#h)"
      />
      <defs>
        <path id="j" d="M1.3 32zm0-.1zm0 0z" />
      </defs>
      <clipPath id="k">
        <use xlinkHref="#j" overflow="visible" />
      </clipPath>
      <linearGradient
        id="l"
        x1="522.2"
        x2="536.8"
        y1="830.9"
        y2="830.9"
        gradientTransform="scale(4.2425) rotate(2.6 18382.1 -10788.4)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#148ece" />
        <stop offset=".6" stopColor="#2378bd" />
        <stop offset="1" stopColor="#2b6bb4" />
      </linearGradient>
      <path fill="url(#l)" d="M1.2 32h.1v-.1h-.1z" clipPath="url(#k)" />
      <path
        fill="#282F39"
        d="M203.5 74.3c0-3.2 2.4-5.6 5.6-5.6 3.1 0 5.7 2.4 5.7 5.6v22c3.6-5.2 8.7-9.3 16.7-9.3 11.6 0 22.9 9.1 22.9 25.5v.2c0 16.3-11.2 25.6-22.9 25.6-8.2 0-13.3-4.2-16.7-8.7v2.5a5.6 5.6 0 0 1-11.3 0V74.3zm39.4 38.4v-.2c0-9.5-6.4-15.7-14-15.7-7.7 0-14.4 6.3-14.4 15.7v.2c0 9.4 6.7 15.8 14.3 15.8 7.8 0 14.1-6 14.1-15.8M266 93.1c0-3.1 2.4-5.6 5.6-5.6 3.2 0 5.7 2.5 5.7 5.6v5c2.6-6 7.5-10.7 12.4-10.7 3.5 0 5.6 2.3 5.6 5.6 0 3-2 4.9-4.5 5.4-8 1.4-13.5 7.5-13.5 19.2V132c0 3-2.5 5.6-5.7 5.6-3 0-5.6-2.4-5.6-5.6v-39zM300.1 113v-.3c0-14 11.2-25.7 26.3-25.7a25.5 25.5 0 0 1 26.2 25.5v.2c0 14-11.2 25.6-26.4 25.6-15 0-26-11.4-26-25.4m41.1 0v-.2c0-8.7-6.2-15.8-15-15.8-9.1 0-14.9 7-14.9 15.6v.2c0 8.6 6.3 15.8 15 15.8 9.2 0 15-7.1 15-15.6M360.8 123v-.1c0-10.6 8.3-15.9 20.3-15.9 5.5 0 9.5.9 13.3 2V108c0-7-4.3-10.7-12.2-10.7-4.3 0-7.9.7-11 2l-1.7.2c-2.6 0-4.8-2-4.8-4.7a5 5 0 0 1 3.1-4.4 42 42 0 0 1 15.9-3c7.3 0 12.8 2 16.3 5.6 3.6 3.5 5.3 8.7 5.3 15.2v24.2c0 3-2.4 5.4-5.5 5.4-3.3 0-5.5-2.2-5.5-4.8v-1.8a20 20 0 0 1-16 7.2c-9.3 0-17.5-5.4-17.5-15.2m33.8-3.6v-3.3c-3-1.2-6.8-2-11.2-2-7.3 0-11.6 3.1-11.6 8.2v.2c0 4.8 4.2 7.5 9.6 7.5 7.5 0 13.2-4.3 13.2-10.6M467.2 132.1c0 3.2-2.5 5.6-5.6 5.6a5.6 5.6 0 0 1-5.7-5.6v-3a19.7 19.7 0 0 1-16.8 9.2c-11.5 0-22.8-9.3-22.8-25.6v-.2c0-16.3 11-25.5 22.8-25.5 8.1 0 13.2 4 16.8 8.7V74.3c0-3.2 2.5-5.6 5.6-5.6 3.2 0 5.7 2.4 5.7 5.6v57.8zm-39.6-19.6v.2c0 9.5 6.6 15.8 14.2 15.8s14.3-6.4 14.3-15.8v-.2c0-9.5-6.7-15.7-14.3-15.7-7.7 0-14.2 6-14.2 15.7"
      />
    </svg>
  );
}

export default BroadCloud;
