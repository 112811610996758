import { SvgProps } from '..';
import { appThemeSettings } from '../const/theme';

function LoginBackground(props: SvgProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      style={props.style}
      viewBox="0 0 1920 331.403"
    >
      <path
        d="M1920 111.679v219.717c-640 .007-1280 .007-1920 .007v-300c1.28.336 2.567.646 3.839 1.012 111.626 32.148 225.28 54.885 339.885 72.978 47.569 7.51 95.327 13.879 143.092 20.042 34.283 4.424 68.698 7.858 103.094 11.343 34.912 3.538 69.85 6.875 104.819 9.784 32.669 2.718 65.378 5.032 98.1 7.024 37.891 2.307 75.801 4.392 113.727 5.979 41.271 1.727 82.563 3.431 123.86 3.967 68.969.894 137.953 1.435 206.924 1.007 50.628-.314 101.251-2.26 151.866-3.785 25.632-.772 51.251-2.07 76.862-3.41 25.435-1.331 50.854-2.957 76.274-4.561 16.111-1.017 32.217-2.135 48.312-3.38 18.742-1.45 37.474-3.033 56.202-4.662 15.907-1.384 31.81-2.827 47.699-4.407 17.866-1.776 35.724-3.649 53.568-5.636 18.335-2.041 36.665-4.135 54.967-6.453 21.423-2.713 42.829-5.573 64.211-8.595 17.59-2.486 35.134-5.302 52.699-7.971z"
        fill={appThemeSettings.backgGroundPathFill1}
      />
      <path
        d="M1920 331.396c-640 .008-1280 .008-1920 .008V0c1.28.371 2.567.714 3.839 1.118 111.626 35.513 225.28 60.63 339.885 80.617 47.569 8.296 95.327 15.332 143.092 22.14 34.283 4.887 68.698 8.681 103.094 12.53 34.912 3.908 69.85 7.595 104.819 10.808 32.669 3.003 219.549 17.489 252.271 19.69 37.891 2.548 421.57 34.5 973 18.798"
        opacity=".4"
        fill={appThemeSettings.backgGroundPathFill2}
      />
    </svg>
  );
}

export default LoginBackground;
