import { Router, SessionProvider } from './lib';
import './global.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarContextProvider } from './lib/context/SnackbarContext';

const queryClient = new QueryClient();

const App = () => {
  return (
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        <SnackbarContextProvider>
          <Router />
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </SnackbarContextProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
};

export default App;
