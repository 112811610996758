import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from '@emotion/react';
import { appTheme, Http } from './lib';
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const fetchLicenseKey = async () => {
  try {
    const licenseKey = await Http.get<string>('jwks/mui', 'text');

    if (typeof licenseKey === 'string' && licenseKey) {
      return licenseKey;
    } else {
      console.warn(
        'MUI license key not found or invalid from server. Falling back to local environment variable.'
      );
      return null;
    }
  } catch (error: any) {
    console.error('Error fetching MUI license key:', error.message);
    console.error(
      'Raw response:',
      error.response ? error.response.data : 'No response data'
    );
    return null;
  }
};

const initApp = async () => {
  let licenseKey = await fetchLicenseKey();

  // If server fetch failed, try to use the local environment variable
  if (!licenseKey) {
    licenseKey = process.env.REACT_APP_MUI_LICENSE_KEY || null;
    if (licenseKey) {
      console.log('Using MUI license key from local environment variable.');
    } else {
      console.warn('No MUI license key available. Some features may be limited.');
    }
  }

  // Set the license key if we have one from either source
  if (licenseKey) {
    LicenseInfo.setLicenseKey(licenseKey);
  }

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={appTheme}>
        <React.StrictMode>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.StrictMode>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

initApp();
