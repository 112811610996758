import React, { useState } from 'react';
import { authService, User } from '../..';

interface SessionContextModel {
  user?: User;
  login: typeof authService.login;
  verify: typeof authService.verify;
  logout: typeof authService.logout;
}

const login = authService.login;
const verify = authService.verify;
const logout = authService.logout;

const SessionContext = React.createContext<SessionContextModel>({
  login,
  verify,
  logout,
});

const SessionProvider = ({ children }: any) => {
  // First check if we are visiting authenticate routes
  authService.checkAuthenticatedRoutes();
  // Try get session
  const [user] = useState<User | undefined>(authService.checkSession());

  return (
    <SessionContext.Provider value={{ user, login, verify, logout }}>
      {children}
    </SessionContext.Provider>
  );
};

const useSession = () => React.useContext(SessionContext);

export { SessionProvider, useSession };
