import { createContext, useContext, useState, useRef } from 'react';
import { Snackbar } from '../../components/snackbar/Snackbar';
import { AlertColor, SnackbarCloseReason } from '@mui/material';

type SnackbarOptions = {
  autoHideDuration?: number | null;
  type?: AlertColor;
};

export type SnackbarState = {
  open: boolean;
  message: string;
  openSnackbar: (message: string, options?: SnackbarOptions) => void;
  autoHideDuration?: number | null;
};

type SnackbarContextProviderProps = {
  children: React.ReactNode;
};

export const SNACKBAR_STATE_DEFAULT: SnackbarState = {
  open: false,
  message: '',
  openSnackbar: () => {},
  autoHideDuration: 4000,
};

export const SnackbarContext = createContext<SnackbarState | null>(null);

export function SnackbarContextProvider({
  children,
}: SnackbarContextProviderProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [options, setOptions] = useState<SnackbarOptions>({
    autoHideDuration: 4000,
    type: 'success',
  });
  const snackbarRef = useRef<HTMLElement>(null);

  const closeSnackbar = () => {
    const onTransitionEnd = () => {
      setMessage('');
      snackbarRef.current
        ?.querySelector('#snackbarContent')
        ?.removeEventListener('transitionend', onTransitionEnd);
    };
    snackbarRef.current
      ?.querySelector('#snackbarContent')
      ?.addEventListener('transitionend', onTransitionEnd);
    setOpen(false);
  };

  const onClose = (
    event: React.SyntheticEvent | Event,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    closeSnackbar();
  };

  const handleCloseClick = (event: React.SyntheticEvent | Event) => {
    closeSnackbar();
  };

  const openSnackbar = (message: string, options?: SnackbarOptions) => {
    if (options) {
      setOptions((prev) => ({ ...prev, ...options }));
    }
    setMessage(message);
    setOpen(true);
  };

  return (
    <SnackbarContext.Provider
      value={{
        open,
        message,
        openSnackbar,
        autoHideDuration:
          typeof options.autoHideDuration === 'undefined'
            ? SNACKBAR_STATE_DEFAULT.autoHideDuration
            : options.autoHideDuration,
      }}
    >
      {children}
      <Snackbar
        open={open}
        type={options.type}
        message={message}
        autoHideDuration={
          typeof options.autoHideDuration === 'undefined'
            ? SNACKBAR_STATE_DEFAULT.autoHideDuration
            : options.autoHideDuration
        }
        onClose={onClose}
        onCloseClick={handleCloseClick}
        snackbarRef={snackbarRef}
      />
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }

  return context;
}
