import {
  Card,
  IconButton,
  Typography,
  Snackbar as MuiSnackbar,
  Alert,
  AlertColor,
  SlideProps,
  Slide,
} from '@mui/material';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { appTheme } from '../../lib';
import { SnackbarState } from '../../lib/context/SnackbarContext';

export type SnackbarProps = React.ComponentProps<typeof MuiSnackbar> &
  Omit<SnackbarState, 'openSnackbar'> & {
    error?: boolean;
    onCloseClick?: (event: React.SyntheticEvent | Event) => void;
    type?: AlertColor;
    snackbarRef?: React.RefObject<HTMLElement>;
  };

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

export function Snackbar(props: SnackbarProps) {
  const {
    open,
    message,
    autoHideDuration,
    onClose,
    onCloseClick,
    type,
    snackbarRef,
  } = props;

  return (
    <MuiSnackbar
      key={message}
      open={open}
      message={message}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      TransitionComponent={SlideTransition}
      ref={snackbarRef}
    >
      <Alert
        variant="filled"
        severity={type}
        id="snackbarContent"
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Typography
          variant="body2"
          fontSize={12}
          fontFamily="Poppins"
          fontWeight={500}
          display="inline"
          color="white"
          pl={2}
          pr={3}
        >
          {message}
        </Typography>
        <IconButton size="small" onClick={onCloseClick}>
          <CloseOutlinedIcon sx={{ color: '#fff', fontSize: '20px' }} />
        </IconButton>
      </Alert>
    </MuiSnackbar>
  );
}
