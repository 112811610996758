export function localeDateFormatter(date: Date) {
  return date.toLocaleString('en-AU', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function secondsToDaysHoursMinsSecs(totalSeconds: number) {
    const ONE_MIN = 60;
    const ONE_HOUR = 60 * ONE_MIN;
    const ONE_DAY = 24 * ONE_HOUR;

    const displayUnit = (value: number, unit: string) => value > 0 ? `${value} ${unit}${value > 1 ? 's' : ''} ` : '';

    const days = Math.floor(totalSeconds / ONE_DAY);
    const hours = Math.floor((totalSeconds % ONE_DAY) / ONE_HOUR);
    const minutes = Math.floor((totalSeconds % ONE_HOUR) / ONE_MIN);
    const seconds = Math.floor(totalSeconds % ONE_MIN);
    
    const daysDisplay = displayUnit(days, 'day');
    const hoursDisplay = displayUnit(hours, 'hour');
    const minutesDisplay = displayUnit(minutes, 'minute');
    const secondsDisplay = displayUnit(seconds, 'second');

    const fullDisplay = `${daysDisplay}${hoursDisplay}${minutesDisplay}${secondsDisplay}`.trim();

    return fullDisplay;
  }
