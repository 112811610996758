import { createTheme, ThemeOptions } from '@mui/material';
import { green, lightBlue, red } from '@mui/material/colors';

import { EngineRoomLogoBlackStacked, EngineRoomLogoWhite } from '../../components/EngineRoomLogos';


// EngineRoom Theme

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: '#17173A',
      contrastText: '#fff',
    },
    secondary: {
      main: '#00C2AF',
      dark: '#852800',
    },
    success: {
      main: green[600],
      dark: green[500],
    },
    error: {
      main: red[500],
      dark: red[500],
    },
    info: {
      main: lightBlue[600],
      dark: lightBlue[500],
    },
  },
  typography: {
    fontFamily: 'Poppins',
    fontSize: 13,
    htmlFontSize: 17,
    button: {
      fontSize: '.9em',
      lineHeight: 2,
      letterSpacing: '0.08em',
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '.9em',
        },
      },
    },
  },
};

export const appTheme = createTheme(theme);

export const appThemeSettings = {
  loginLogo: EngineRoomLogoBlackStacked,
  loginTitle: 'Service Provider Migration Service',
  favicon:
    'https://netstorage.ringcentral.com/appext/logo/TFQ9Uh2rTbiYGu9M_R7pAw~yV0_eNqeT6-sWPXFJev-6A/c448bc9c-339e-4d73-b962-59b2c3a96350.png',
  pageTitle: 'EngineRoom Migration Service',
  customerColumnLabel: 'Customer Number',
  businessCenterLabel: 'Business Center',
  name: 'EngineRoom',
  backgGroundPathFill1: '#17173A',
  backgGroundPathFill2: '#2D2D5F',
  headerLogo: EngineRoomLogoWhite,
};
