import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { appRoutes } from '../const/routes';
import { useNavigate } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { appTheme } from '../const/theme';

interface SidebarProps {
  menuOpen: boolean;
  sidebarWidth: number;
}

function Sidebar({ menuOpen, sidebarWidth }: SidebarProps) {
  const navigate = useNavigate();
  return (
    <Drawer
      open={menuOpen}
      variant="persistent"
      sx={{
        width: menuOpen ? sidebarWidth : 0,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: menuOpen ? sidebarWidth : 0,
          boxSizing: 'border-box',
        },
        transition: 'all 120ms ease-in-out',
      }}
    >
      <Box sx={{ marginTop: '60px', overflow: 'auto' }}>
        <List disablePadding>
          {appRoutes
            .filter((r) => !r.hideInMenu)
            .map((r, i) => {
              const { icon: Icon } = r;
              const { pathname: currentPath } = window.location;
              const isCurrent = r.path === currentPath;
              return (
                <ListItem
                  key={`sidebar_li_${i}`}
                  disablePadding
                  sx={{
                    background: isCurrent
                      ? appTheme.palette.grey[100]
                      : undefined,
                  }}
                >
                  {r.path && (
                    <ListItemButton
                      onClick={() => {
                        if (r.path) {
                          navigate(r.path);
                        }
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        {Icon && (
                          <Icon
                            margin={0}
                            sx={{
                              color: isCurrent
                                ? appTheme.palette.primary.dark
                                : undefined,
                            }}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        {isCurrent ? (
                          <span
                            style={{
                              fontWeight: 500,
                              color: appTheme.palette.primary.dark,
                            }}
                          >
                            {r.title}
                          </span>
                        ) : (
                          r.title
                        )}
                      </ListItemText>
                    </ListItemButton>
                  )}
                  {!r.path && (
                    <Typography
                      p={1}
                      m={0}
                      mt={i !== 0 ? 1 : i}
                      fontWeight={500}
                      color={grey[500]}
                      variant="button"
                    >
                      {r.title}
                    </Typography>
                  )}
                </ListItem>
              );
            })}
        </List>
      </Box>
    </Drawer>
  );
}

export default Sidebar;
