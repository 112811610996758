import { setCookie, getCookie, removeCookie } from 'typescript-cookie';
import jwt_decode from 'jwt-decode';
import { Http, IResult, IResultModel, JwtPayloadWithUser, LoginResult, OtpResult, User } from '../..';

class AuthService {
  private unAuthenticatedRoutes = ['login'];

  public getReturnUrl = () => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const returnUrl = searchParams.get('return');
    return returnUrl ?? '/';
  };

  public checkAuthenticatedRoutes = (): void => {
    const { pathname } = window.location;
    const token = getCookie('token');
    // check token
    if (
      !token?.length &&
      !this.unAuthenticatedRoutes.includes(pathname.replace('/', ''))
    )
      this.logout(true);
    if (
      token?.length &&
      this.unAuthenticatedRoutes.includes(pathname.replace('/', ''))
    )
      window.location.replace(this.getReturnUrl());
  };

  public checkSession = (): User | undefined => {
    const { origin } = window.location;

    const token = getCookie('token');

    try {
      if (token) {
        const { exp, aud, usr } = jwt_decode<JwtPayloadWithUser>(token);
        const nowMS = Math.round(Date.now() / 1000);

        // check user name, audience and exp is valid
        if (usr?.length && aud === origin && exp && exp > nowMS) {
          return { userName: usr } as User;
        }
      }

      this.logout();
      return undefined;
    } catch {
      this.logout(true);
    }
  };

  public login = async (
    username: string,
    password: string,
    rememberMe: boolean
  ): Promise<IResult> => {
    const response = await Http.post<IResultModel<LoginResult>>('auth/login', {
      username,
      password,
      rememberMe,
    });

    if (response.isValid && response.model?.authenticated) {
      return Promise.resolve({ isValid: true });
    }

    const error: IResultModel<LoginResult> = { ...response, isValid: false };

    return Promise.resolve(error);
  };

  public verify = async (username: string, otp: string) => {
    const response = await Http.post<IResultModel<OtpResult>>('auth/otp', {
      username,
      otp,
    });

    if (response.isValid && response.model?.token.length) {
      // save auth cookie
      setCookie('token', response.model.token, { expires: 1, path: '/' });

      // redirect
      setTimeout(() => window.location.replace(this.getReturnUrl()), 1000);

      return Promise.resolve({ isValid: true });
    }

    const error: IResultModel<OtpResult> = { ...response, isValid: false };

    return Promise.resolve(error);
  };

  public logout = (redirect = false) => {
    if (redirect) {
      setTimeout(() => {
        Http.post('auth/logout');
        removeCookie('token');
        removeCookie('session');
        window.location.assign(`/login?return=${window.location.pathname}`);
      }, 100);
    } else {
      removeCookie('token');
      removeCookie('session');
    }
  };

  // initiates oauth provider flow
  public initOAuth = async (provider: string) => {
    const redirectUrl = await Http.get<IResultModel<string>>(
      `auth/oauth/${provider}`
    );

    if (redirectUrl?.model?.length) window.location.assign(redirectUrl.model);

    return redirectUrl;
  };

  public revokeOAuth = async (provider: string) => {
    const result = await Http.delete<IResult>(`auth/oauth/${provider}`);

    if (result.isValid) setTimeout(() => window.location.reload(), 500);

    return result;
  };

  // authenticate with jwt flow
  public initJWTAuth = async (provider: string) => {
    const accessToken = await Http.get<IResultModel<string>>(
      `auth/jwt/${provider}`
    );

    return accessToken;
  };
}

const authService = new AuthService();

export default authService;
