import * as React from 'react';
import { Box, CircularProgress, Fade, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './Header';
import { useState } from 'react';
import Sidebar from './Sidebar';

const sidebarWidth = 240;

interface ContainerProps {
  loading: boolean;
  children?: React.ReactNode;
}

export default function Container({ loading, children }: ContainerProps) {
  const [drawerOpen, setDrawerOpen] = useState(
    !sessionStorage.getItem('drawerOpen')?.length
      ? true
      : sessionStorage.getItem('drawerOpen') === 'true'
  );
  const toggleDrawer = () => {
    sessionStorage.setItem('drawerOpen', (!drawerOpen).toString());
    setDrawerOpen(!drawerOpen);
  };
  return (
    <Box sx={{ display: 'flex', minHeight: '100%' }}>
      <CssBaseline />
      <Header
        menuOpen={drawerOpen}
        sidebarWidth={sidebarWidth}
        toggleSidebar={() => toggleDrawer()}
      />
      <Sidebar menuOpen={drawerOpen} sidebarWidth={sidebarWidth} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          paddingTop: '60px',
          background: '#f4f4f4',
        }}
      >
        <Fade in={!loading} mountOnEnter={true}>
          <Box sx={{ height: '100%' }}>{children}</Box>
        </Fade>
        <Fade in={loading} mountOnEnter={true}>
          <Box
            height="inherit"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Box textAlign="center">
              <CircularProgress sx={{ margin: 0 }} />
              <Typography
                mt={1}
                variant="button"
                display="block"
                fontWeight={600}
                sx={{ opacity: '50%' }}
              >
                Loading...
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Box>
    </Box>
  );
}
